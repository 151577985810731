<template>
    <div class="login_win">
        <div class="login_dia">
            <img class="close_btn" @click="closeDia" src="../../assets/9-1.png" />
            <p class="title">Connect Wallet</p>
            <div class="login_btn_main">
                <!-- <div class="login_btn">
                    <img src="../../assets/9-2.png">
                    <p>MetaMask</p>
                </div>
                 <div class="login_btn">
                    <img src="../../assets/9-3.png">
                    <p>OKX Wallet</p>
                </div>
                <div class="login_btn">
                    <img src="../../assets/9-4.png">
                    <p>Wallet Connect</p>
                </div> -->
                <div>
                    <w3m-button size="md" loadingLabel="connecting..."/>
                </div>
                <div>
                    <p class="login_dia_text">
                        Wedo not own your private keys and cannot access your funds without your confirmation
                    </p>
                </div>
            </div>

        </div>
    </div>
</template>
<script setup="ts">

    import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/vue'
    // this.isVisible = true;
    // 1. Get projectId at https://cloud.walletconnect.com
    const projectId = '7d8766d4c6dc974529ffffc1b46f76a4'

    // 2. Set chains
    const mainnet = {
        chainId: 1,
        name: 'Ethereum',
        currency: 'ETH',
        explorerUrl: 'https://etherscan.io',
        rpcUrl: 'https://cloudflare-eth.com'
    }

    // 3. Create modal
    const metadata = {
        name: 'Wowfish',
        description: 'Wowfish game',
        url: 'https://localhost:8080/', // origin must match your domain & subdomain
        icons: ['../../assets/9-4.png']
    }

    createWeb3Modal({
        ethersConfig: defaultConfig({ metadata }),
        chains: [mainnet],
        projectId,
        enableAnalytics: true // Optional - defaults to your Cloud configuration
    }),





    function closeDia (){
        this.$emit('closeDia');
    }
    
</script>


<style scoped lang="less">
@import url("../../css/common.less");
    .login_win{
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.6);
        top: 0;
        left: 0;
        z-index: 20;
    }
   .login_dia{
        width: @size-420;
        height: @size-420;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background: #fff;
        border-radius: 10px;
        .title{
            font-size: @size-36;
            padding: @size-36 @size-28 @size-36;
            font-weight: bold;
        }
        .close_btn{
            width: @size-32;
            margin: @size-22 @size-22 0 0;
            float: right;
            cursor: pointer;
        }
        .login_btn_main{
             padding: 0 @size-28;
        }
        .login_btn{
            padding: @size-12 @size-30;
            cursor: pointer;
            background: #f4f6f9;
            display: flex;
            align-items: center;
            justify-content: left;
            margin-bottom: @size-20;
            border-radius: 4px;
            img{
                width: @size-36;
                vertical-align: middle;
            }
            p{
                font-size: @size-18;
                padding-left: @size-36;
            }
        }
   }
   .login_dia_text{
        font-size:@size-14;
        color: #cfcfcf;
   }
    @media screen and (max-width: 1300px) {

    }
    @media screen and (min-width: 1920px) {
  .login_dia{
        width: 420px;
        height: 420px;
        .title{
            font-size: 36px;
            padding: 36px 28px 36px;
        }
        .close_btn{
            width: 32px;
            margin: 22px 22px 0 0;
        }
         .login_btn_main{
             padding: 0 28px;
        }
        .login_btn{
            padding: 12px 30px;
            margin-bottom: 20px;
            img{
                width: 36px;
            }
             p{
                font-size: 18px;
                 padding-left: 36px;
            }
        }
         .login_dia_text{
                font-size:14px;
                color: #cfcfcf;
        }
   }
    }
</style>