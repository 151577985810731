<template>
    <div style="position:relative;min-height:100vh">
    <NavBox :op='true'></NavBox>
     <img class="bottom_bg" src="../../assets/b-2.png">
    <div class="white_main">
       <div class="nft_menu_l">
                            <div class="nft_menu_li">
                                <div class="nft_menu_li_t" :class="{'isactive': menuInd == 0}" @click="menuIndChange(0)">HEADGEAR</div>
                                <div class="nft_sub_main" v-if="menuInd == 0">
                                    <div class="nft_sub_li" :class="{'isactive': subMenuInd == '0-1'}" @click="subMenuIndChange('0-1')">BLUE</div>
                                     <div class="nft_sub_li">BLUE</div>
                                      <div class="nft_sub_li">BLUE</div>
                                </div>
                            </div>
                            <div class="nft_menu_li" >
                                <div class="nft_menu_li_t" :class="{'isactive': menuInd == 1}" @click="menuIndChange(1)">HEADGEAR</div>
                                  <div class="nft_sub_main" v-if="menuInd == 1">
                                    <div class="nft_sub_li">BLUE</div>
                                     <div class="nft_sub_li">BLUE</div>
                                      <div class="nft_sub_li">BLUE</div>
                                </div>
                            </div>
                            <div class="nft_menu_li" >
                                <div class="nft_menu_li_t" :class="{'isactive': menuInd == 2}" @click="menuIndChange(2)">HEADGEAR</div>
                                  <div class="nft_sub_main" v-if="menuInd == 2">
                                    <div class="nft_sub_li">BLUE</div>
                                     <div class="nft_sub_li">BLUE</div>
                                      <div class="nft_sub_li">BLUE</div>
                                </div>
                            </div>
                            <div class="nft_menu_li" >
                                <div class="nft_menu_li_t" :class="{'isactive': menuInd == 3}" @click="menuIndChange(3)">HEADGEAR</div>
                                  <div class="nft_sub_main" v-if="menuInd == 3">
                                    <div class="nft_sub_li">BLUE</div>
                                     <div class="nft_sub_li">BLUE</div>
                                      <div class="nft_sub_li">BLUE</div>
                                </div>
                            </div>
        </div>
        <div class="white_main_r">
            <p class="title">WHITERAPER</p>
            <p class="white_main_info">Unleash the ultimate meme thrill with 60-second card battles!</p>
            <img class="white_main_img" src="../../assets/6-1.png" />
            <pre class="white_main_content">
Experience the thrill of rapid-fire gameplay with the first 60-second card game, Cards Ahoy! It's all about battling with meme cards in a fast-paced, easy-to-learn way. Unlock an awesome stress-relief experience, utilizing your strategy and wit to compete with players worldwide and play to earn rewards. Join our early gacha event for a chance to obtain limited-edition crossover meme cards.

Experience the thrill of rapid-fire gameplay with the first 60-second card game, Cards Ahoy! It's all about battling with meme cards in a fast-paced, easy-to-learn way. Unlock an awesome stress-relief experience, utilizing your strategy and wit to compete with players worldwide and play to earn rewards. Join our early gacha event for a chance to obtain limited-edition crossover meme cards.
            </pre>
        </div>
    </div>
    </div>
</template>
<script>
import {goScrollTop} from "../../utils/common.js"
import NavBox from '../../components/NavBox/NavBox.vue'
import { ref } from 'vue';
export default {
    setup() {
        goScrollTop()
         let menuInd = ref(0)
        let subMenuInd = ref('0-1')
        const menuIndChange = function(ind){
            menuInd.value = ind
        }
         const subMenuIndChange = function(ind){
            subMenuInd.value = ind
        }
        return{
            subMenuInd,
            subMenuIndChange,
            menuInd,
            menuIndChange,
        }
    },
     components:{
        NavBox,
    }
}
</script>
<style scoped lang="less">
@import url("../../css/common.less");
    .white_main{
        width: @size-1300;
        margin: 0 auto;
        padding-bottom:13.75vw ;
        padding-top: 7.6vw;
        position: relative;
        z-index: 1;
        .nft_menu_l{
                display: inline-block;
                background: #f4f6f9;
                width: @size-240;
                padding: @size-40;
                border-radius: 1vw;
                min-height: @size-620;
                vertical-align: top;
                .nft_menu_li{
                   
                    margin-bottom: @size-20;
                    
                    .nft_menu_li_t{
                         height: @size-40;
                        padding: 0 @size-36 0 @size-18;
                        border-radius: 0.4vw;
                        background: #f4f6f9;
                        line-height: @size-40;
                        font-weight: bold;
                        cursor: pointer;
                        font-size: @size-18;
                        position: relative;
                        color: #666666;
                        &:hover{
                            background: #fff;
                            // color: #157cf6;
                        }
                       
                        &::after{
                            content: "";
                            position: absolute;
                            width: 0.6vw;
                            height: @size-16;
                            background: url("../../assets/4-5.png") no-repeat 0 0;
                            background-size: 100%;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 1vw;
                        }
                         &.isactive{
                            background: #fff;
                            color: #157cf6;
                             &::after{
                                content: "";
                                width: @size-12;
                                height: 0.5vw;
                                background: url("../../assets/4-6.png") no-repeat 0 0;
                                background-size: 100%;
                                 right: 1.1vw;
                            }
                        }
                    }
                    .nft_sub_li{
                        position: relative;
                        padding-left: 3vw;
                        height: @size-40;
                        margin: 0.2vw 0;
                        line-height: @size-40;
                        font-weight: bold;
                        font-size: @size-16;
                        color: #999999;
                        border-radius: 0.4vw;
                        cursor: pointer;
                        &::before{
                            content:"";
                            width: @size-20;
                            height: @size-20;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            background: url("../../assets/4-2.png") no-repeat 0 0;
                            background-size:100% ;
                            left: 1.5vw;
                        }
                        &:hover{
                             background: #fff;
                        }
                        &.isactive{
                            background: #fff;
                        }
                    }
                }
            }
            .white_main_r{
                display: inline-block;
                vertical-align: top;
                width: 46.2vw;
                padding-left: 4.5vw;
                .title{
                    font-size: @size-48;
                    font-weight: bold;
                }
                .white_main_info{
                    font-size: @size-24;
                    padding-top: @size-36;
                }
                .white_main_img{
                    margin-top: @size-38;
                    width: 100%;
                }
                .white_main_content{
                    width: 100%;
                    font-size: @size-16;
                    white-space: pre-wrap;
                }
            }
    }
    .bottom_bg{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
</style>