
import axios from 'axios';
import MD5 from 'crypto-js/md5';

export const goScrollTop = ()=>{
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
}

export const  postEventToken=function(url,data,sCallback,eCallback){
    axios({
        method:'post',
        url:url,
        headers: {  'content-type': 'application/json'},
        withCredentials: false,
        data: data
    }).then((res)=>{
       console.log(res)
       sCallback && sCallback(res)
    }).catch((err)=>{
        console.log(err);
        eCallback && eCallback(err);
    })
}

export const getRankList =  async function getRankList(type){
    const urlProd='https://services.wowfish.io:23200'
    // const urlDev='https://testchainfsih.gz-cube.com:8821'
    const rankUrl= urlProd + '/game/rank';
    const pageindex = 0;
    const pagenum = 100;
    const allcount = 0;
    const ranktype = type == 0 ? 0 : 1;
    const security_key = "123&*abc";
    const paramers = { "pageindex": pageindex, "pagenum": pagenum, "allcount": allcount,"ranktype": ranktype,"security_key":security_key};
    const sign = generateMD5ForSortedParams(paramers);
    console.log(paramers);
    const resp = await axios({
        method:'post',
        url:rankUrl,
        headers: {'content-type': 'application/json'},
        data: { "pageindex": pageindex, "pagenum": pagenum, "allcount": allcount,"ranktype": ranktype,"sign": sign}
    })
    return resp.data;
}

function generateMD5ForSortedParams(params) {
    const paramsArray = Object.entries(params);
    paramsArray.sort((a, b) => a[0].localeCompare(b[0]));
    const concatenatedParams = paramsArray.map(pair => `${pair[0]}=${pair[1]}`).join('&');
    const md5Value = MD5(concatenatedParams).toString();
    return md5Value;
}

