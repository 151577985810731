<template>
 <div style="position:relative;min-height:100vh">
    <NavBox :op='true'></NavBox>
    <div class="assets_main">
        <p class="title">TOKEN ASSET</p>
        <div class="assets_menu">
            <div class="assets_box">
                <p class="title">tsfs</p>
                <p class="content">$85,235</p>
            </div>
            <div class="assets_box">
                <p class="title">tsfs</p>
                <p class="content">$85,235</p>
            </div>
            <div class="assets_box">
                <p class="title">tsfs</p>
                <p class="content">$85,235</p>
            </div>
        </div>
         <p class="title">MY NFT</p>
         <div class="mon_menu">
            <div class="mon_menu_box"></div>
            <div class="mon_menu_box p1"></div>
            <div class="mon_menu_box p2"></div>
            <div class="mon_menu_box"></div>
            <div class="mon_menu_box p1"></div>
            <div class="mon_menu_box p2"></div>
            <div class="mon_menu_box"></div>
            <div class="mon_menu_box p1"></div>
            <div class="mon_menu_box p2"></div>
            <div class="mon_menu_box"></div>
            <div class="mon_menu_box p1"></div>
            <div class="mon_menu_box p2"></div>
         </div>
         
    </div>
         <img class="bottom_bg" src="../../assets/b-2.png">
 </div>
</template>
<script>
import {goScrollTop} from "../../utils/common.js"
import { defineComponent } from 'vue'
import NavBox from '../../components/NavBox/NavBox.vue'
export default defineComponent({
    setup(){
        goScrollTop()
    },
     components:{
        NavBox,
    }
})
</script>
<style lang="less" scoped>
@import url("../../css/common.less");
.assets_main{
    width: @size-1300;
    margin: 0 auto;
    padding-bottom: @size-240;
    padding-top: 4.8vw;
    .title{
        font-size: @size-36;
        font-weight: bold;
        padding-top: @size-54;
    }
    .assets_menu{
        display: flex;
        flex-wrap: wrap;
        .assets_box{
            width: @size-410;
            height: @size-150;
            background: url('../../assets/7-2.png') no-repeat 0 0;
            background-size: 100%;
             margin-right: @size-30;
             margin-top: @size-30;
             color: #fff;
            &:nth-child(3n){
                margin-right: 0;
            }
            .title{
                padding: @size-38 0 0 @size-32;
                font-size: @size-24;
            }
            .content{
                padding: @size-10 0 0 @size-32;
                font-size: @size-30;
                font-weight: bold;
            }
        }
     
    }
       .mon_menu{
            display: flex;
            flex-wrap: wrap;
            .mon_menu_box{
                width: 15.5vw;
                height: 15.5vw;
                background: url("../../assets/7-4.png") no-repeat 0 0 ;
                background-size:100% ;
                margin-top: @size-30;
                margin-right: @size-32;
                &:nth-child(4n){
                    margin-right: 0;
                }
                &.p1{
                    background: url("../../assets/7-3.png") no-repeat 0 0 ;
                    background-size:100% ;
                }
                &.p2{
                    background: url("../../assets/7-5.png") no-repeat 0 0 ;
                    background-size:100% ;
                }
            }
        }
}
    .bottom_bg{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
</style>