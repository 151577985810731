<template>
<NavBox></NavBox>
    <div>
        <div class="nft_head">
            <img src="../../assets/b-1.png" />
            <div class="nft_head_main">
                <img class="nft_head_img" src="../../assets/0-3-2.png" />
                <div class="nft_head_info">
                    <p class="title">Wowfish NFT</p>
                    <p class="info_text">This is the main content copyThis is themain content copyThis is the main </p>
                    <p class="info_content">Remaining --------------- 100/1000</p>
                    <p class="info_content">Price  ------------------- 1ETH</p>
                    <p class="info_content">Max Mint Amount ---------- 1</p>
                    <div class="nft_btn disable">
                        Mint
                    </div>
                    <p class="date_title">Public Mint Ends In</p>
                    <div class="date_box">
                        <span>12</span>
                        <span class="p1">D</span>
                        <span class="p2">08</span>
                        <p>:</p>
                        <span class="p2">10</span>
                        <p>:</p>
                        <span class="p2">1</span>
                    </div>
                </div>
                <img class="nft_head_bg" src="../../assets/1-3-2.png" />
            </div>
       
        </div>
             <div class="nft_main">
                <p class="title">NFT PRIVILEGES</p>
                <div class="nft_main_list">
                    <div class="nft_main_box">
                        <img src="../../assets/5-1.png" />
                        <p>This is the main content copy，This is the main </p>
                    </div>
                    <div class="nft_main_box">
                        <img src="../../assets/5-2.png" />
                        <p>This is the main content copy，This is the main </p>
                    </div>
                    <div class="nft_main_box">
                        <img src="../../assets/5-3.png" />
                        <p>This is the main content copy，This is the main </p>
                    </div>
                    <div class="nft_main_box">
                        <img src="../../assets/5-4.png" />
                        <p>This is the main content copy，This is the main </p>
                    </div>
                </div>
                 <p class="title">GALLERY</p>
                 <div class="nft_main_menus">
                    <div class="nft_tab_main">
                        <div class="tab_btn_main">
                            <div class="tab_btn" @click="tabChange(0)" :class="{'isactive': tabInd == 0}">Collection1<span v-if="tabInd == 0" class="line"></span></div>
                            <div class="tab_btn" @click="tabChange(1)" :class="{'isactive': tabInd == 1}">Collection1<span v-if="tabInd == 1" class="line"></span></div>
                            <div class="tab_btn" @click="tabChange(2)" :class="{'isactive': tabInd == 2}">Collection1<span v-if="tabInd == 2" class="line"></span></div>
                        </div>
                        <div class="tab_icon_main">
                            <p>1000</p>
                            <img src="../../assets/4-3.png" />
                            <img src="../../assets/4-4.png" />
                        </div>
                    </div>
                    <div class="nft_menu_content">
                        <div class="nft_menu_l">
                            <div class="nft_menu_li">
                                <div class="nft_menu_li_t" :class="{'isactive': menuInd == 0}" @click="menuIndChange(0)">HEADGEAR</div>
                                <div class="nft_sub_main" v-if="menuInd == 0">
                                    <div class="nft_sub_li" :class="{'isactive': subMenuInd == '0-1'}" @click="subMenuIndChange('0-1')">BLUE</div>
                                     <div class="nft_sub_li">BLUE</div>
                                      <div class="nft_sub_li">BLUE</div>
                                </div>
                            </div>
                            <div class="nft_menu_li" >
                                <div class="nft_menu_li_t" :class="{'isactive': menuInd == 1}" @click="menuIndChange(1)">HEADGEAR</div>
                                  <div class="nft_sub_main" v-if="menuInd == 1">
                                    <div class="nft_sub_li">BLUE</div>
                                     <div class="nft_sub_li">BLUE</div>
                                      <div class="nft_sub_li">BLUE</div>
                                </div>
                            </div>
                            <div class="nft_menu_li" >
                                <div class="nft_menu_li_t" :class="{'isactive': menuInd == 2}" @click="menuIndChange(2)">HEADGEAR</div>
                                  <div class="nft_sub_main" v-if="menuInd == 2">
                                    <div class="nft_sub_li">BLUE</div>
                                     <div class="nft_sub_li">BLUE</div>
                                      <div class="nft_sub_li">BLUE</div>
                                </div>
                            </div>
                            <div class="nft_menu_li" >
                                <div class="nft_menu_li_t" :class="{'isactive': menuInd == 3}" @click="menuIndChange(3)">HEADGEAR</div>
                                  <div class="nft_sub_main" v-if="menuInd == 3">
                                    <div class="nft_sub_li">BLUE</div>
                                     <div class="nft_sub_li">BLUE</div>
                                      <div class="nft_sub_li">BLUE</div>
                                </div>
                            </div>
                        </div>
                        <div class="nft_menu_r">
                            <div v-if="tabInd == 0">
                                <img src="../../assets/5-5.png" />
                                <img src="../../assets/5-6.png" />
                                <img src="../../assets/5-7.png" />
                                <img src="../../assets/5-8.png" />
                                <img src="../../assets/5-9.png" />
                                <img src="../../assets/5-10.png" />
                                <img src="../../assets/5-11.png" />
                                <img src="../../assets/5-12.png" />
                                <img src="../../assets/5-13.png" />
                            </div>
                            <div v-if="tabInd == 1">
                                <img src="../../assets/5-8.png" />
                                <img src="../../assets/5-9.png" />
                                <img src="../../assets/5-10.png" />
                                <img src="../../assets/5-11.png" />
                                <img src="../../assets/5-12.png" />
                                <img src="../../assets/5-13.png" />
                                <img src="../../assets/5-5.png" />
                             <img src="../../assets/5-6.png" />
                              <img src="../../assets/5-7.png" />
                            </div>
                            
                            <div v-if="tabInd == 2">
                                <img src="../../assets/5-9.png" />
                                <img src="../../assets/5-10.png" />
                                <img src="../../assets/5-11.png" />
                                <img src="../../assets/5-5.png" />
                                <img src="../../assets/5-6.png" />
                                <img src="../../assets/5-7.png" />
                                <img src="../../assets/5-8.png" />
                                <img src="../../assets/5-12.png" />
                                <img src="../../assets/5-13.png" />
                            </div>
                            
                        </div>
                    </div>
                 </div>
                   <img class="bottom_bg" src="../../assets/b-2.png">
            </div>
          
    </div>
</template>
<script>
import {goScrollTop} from "../../utils/common.js"
import NavBox from '../../components/NavBox/NavBox.vue'
import { ref } from 'vue';
export default {
    setup() {
        goScrollTop()
        let menuInd = ref(0)
        let subMenuInd = ref('0-1')
        let tabInd = ref(0)
        const menuIndChange = function(ind){
            menuInd.value = ind
        }
         const subMenuIndChange = function(ind){
            subMenuInd.value = ind
        }
        const tabChange = function(ind){
            tabInd.value = ind
        }
        return{
            menuInd,
            menuIndChange,
            subMenuInd,
            subMenuIndChange,
            tabChange,
            tabInd
        }
    },
     components:{
        NavBox,
    }
}
</script>
<style scoped lang="less">
@import url("../../css/common.less");
    .nft_head{
        position: relative;
        overflow: hidden;
        img{
            width: 100%;
        }
        .nft_head_main{
            width: 68.75vw;
            min-height:28.64vw;
            background: #fff;
            border-radius: 2vw;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
        .nft_head_img{
            width: 42.3vw;
            cursor: pointer;
            margin: @size-38 0 0 @size-26;
        }
        .nft_head_info{
            vertical-align: top;
            display: inline-block;
            width: 19.8vw;
            padding-left: @size-48;
            padding-top: @size-32;
            .title{
                font-size: @size-48;
                font-weight: bold;
            }
            .info_text{
                font-size: @size-16;
                color: #999999;
                line-height: @size-32;
                padding-bottom: @size-30;
            }
            .info_content{
                font-size: @size-18;
                padding-bottom: @size-10;
            }
            .nft_btn{
                width: @size-200;
                height: @size-60;
                background: #157cf6;
                border-radius: 0.5vw;
                cursor: pointer;
                color: #fff;
                font-size: @size-30;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                margin-top: @size-30;
                &:hover{
                    background:#0d45cb ;
                }
                &.disable{
                    background: #bbbbbb;
                    &:hover{
                    background:#bbbbbb ;
                }
                }
            }
            .date_title{
                font-size: @size-18;
                padding-top: @size-38;

            }
            .date_box{
                padding-top: @size-16;
                span{
                    display: inline-block;
                    width: @size-44;
                    height: @size-44;
                    background: url('../../assets/4-1.png') no-repeat 0 0;
                    background-size:100% ;
                    text-align: center;
                    line-height: @size-44;
                    font-size: @size-30;
                    color: #157cf6;
                    font-weight: bold;
                    margin-right: 0.15vw;
                     vertical-align: middle;
                     &.p1{
                        margin-right: @size-10;
                     }
                     &.p2{
                        margin-right: 0;
                     }
                }
                p{
                    display: inline-block;
                    height: @size-44;
                    line-height: @size-44;
                    vertical-align: middle;
                    margin: 0 0.3vw;

                }
            }
        }
        .nft_head_bg{
            width: 10.52vw;
            position: absolute;
            left: -2.8vw;
            bottom: -0.5vw;
        }
    }
    .nft_main{
        .title{
            padding-top:@size-102 ;
            font-size: @size-48;
            font-weight: bold;
            text-align: center;
        }
        .nft_main_list{
            padding-top: @size-62;
            text-align: center;
            .nft_main_box{
                display: inline-block;
                width: 15.6vw;
                margin: 0 @size-16;
                img{
                    width: 100%;
                }
                p{
                    padding: @size-20 0.5vw 0;
                    font-size: @size-18;
                    text-align: left;
                }

            }
        }
    }
    .nft_main_menus{
        padding-top: @size-50;
        width:@size-1300;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        .nft_tab_main{
            padding-left: 18.4vw;
            display: flex;
            justify-content: space-between;
            .tab_btn_main{
                display: flex;
                .tab_btn{
                    font-size: @size-24;
                    font-weight: bold;
                    margin: 0 @size-30;
                    cursor: pointer;
                    position: relative;
                    padding: @size-22 0 @size-24;
                    &.isactive{
                        color: #157cf6;
                        .line{
                            width: 100%;
                            height: 0.2vw;
                            border-radius: 10px;
                            position: absolute;
                            bottom: -0.1vw;
                            left: 0;
                            background: #157cf6;
                            display: block;
                        }
                    }
                    .line{
                        display: none;
                    }
                }
            }
            .tab_icon_main{
                 padding: @size-22 0 @size-24;
                img{
                    width: @size-24;
                     vertical-align: middle;
                     margin: 0 @size-16;
                     cursor: pointer;
                }
                p{
                    display: inline-block;
                    vertical-align: middle;
                    font-size: @size-24;
                    color: #999;
                    margin: 0 @size-16;
                }
            }
        }
        .nft_menu_content{
            padding-bottom: @size-126;
            .nft_menu_l{
                display: inline-block;
                background: #f4f6f9;
                width: @size-240;
                padding: @size-40;
                border-top-left-radius: 1vw;
                border-bottom-left-radius: 1vw;
                border-bottom-right-radius: 1vw;
                min-height: @size-620;
                vertical-align: top;
                .nft_menu_li{
                   
                    margin-bottom: @size-20;
                    
                    .nft_menu_li_t{
                         height: @size-40;
                        padding: 0 @size-36 0 @size-18;
                        border-radius: 0.4vw;
                        background: #f4f6f9;
                        line-height: @size-40;
                        font-weight: bold;
                        cursor: pointer;
                        font-size: @size-18;
                        position: relative;
                        color: #666666;
                        &:hover{
                            background: #fff;
                            // color: #157cf6;
                        }
                       
                        &::after{
                            content: "";
                            position: absolute;
                            width: 0.6vw;
                            height: @size-16;
                            background: url("../../assets/4-5.png") no-repeat 0 0;
                            background-size: 100%;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 1vw;
                        }
                         &.isactive{
                            background: #fff;
                            color: #157cf6;
                             &::after{
                                content: "";
                                width: @size-12;
                                height: 0.5vw;
                                background: url("../../assets/4-6.png") no-repeat 0 0;
                                background-size: 100%;
                                 right: 1.1vw;
                            }
                        }
                    }
                    .nft_sub_li{
                        position: relative;
                        padding-left: 3vw;
                        height: @size-40;
                        margin: 0.2vw 0;
                        line-height: @size-40;
                        font-weight: bold;
                        font-size: @size-16;
                        color: #999999;
                        border-radius: 0.4vw;
                        cursor: pointer;
                        &::before{
                            content:"";
                            width: @size-20;
                            height: @size-20;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            background: url("../../assets/4-2.png") no-repeat 0 0;
                            background-size:100% ;
                            left: 1.5vw;
                        }
                        &:hover{
                             background: #fff;
                        }
                        &.isactive{
                            background: #fff;
                        }
                    }
                }
            }
            .nft_menu_r{
                display: inline-block;
                border-top: 0.15vw solid #f4f6f9;
                width: 49.4vw;
                padding: @size-38 0 0 @size-30;
                vertical-align: top;
                img{
                    width: 14.6vw;
                    margin: 0 0 1.8vw 1.8vw;
                }
            }
        }
    }
    .nft_main{
        position: relative;
        .bottom_bg{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
    }
</style>
