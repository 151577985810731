<template>
  <!-- <router-view></router-view> -->
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
body{
  margin: 0;
  font-family:"PingFang", Arial, sans-serif; /* 定义字体列表 */
  font-size: 16px;
  color: #222222;
}
p{margin: 0;}
#app {
  /* min-width: 75.52vw; */
  min-width: 1300px;
  overflow:hidden;
}
ul{
  margin: 0;
  padding: 0;
}
ul li{
  list-style: none;
}
</style>
