import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "./css/common.less"
import './css/swiper.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'



const app = createApp(App)

console.log(router)
app.use(router).use(ElementPlus).mount('#app')
