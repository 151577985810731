// import Vue from 'vue'
import {createRouter, createWebHashHistory} from 'vue-router'

import HomePage from "../views/Home/HomePage.vue"
import Nft from "../views/Nft/NftPage.vue"
import WHITEPAPER from "../views/WHITEPAPER/WHITEPAPER.vue"
import AssetsPage from "../views/AssetsPage/AssetsPage.vue"
import LEADERBOARD from '@/views/LEADERBOARD/LEADERBOARD.vue'


const routes = [
    {
        path:'/',
        redirect:'/Home'
    },
    {
        name: 'Home',
        path: '/Home',
        component: HomePage
    },
    {
        name: 'NFT',
        path: '/NFT',
        component: Nft
    },
    {
        name: 'WHITEPAPER',
        path: '/WHITEPAPER',
        component: WHITEPAPER
    },
    {
        name: 'AssetsPage',
        path: '/AssetsPage',
        component: AssetsPage
    },
    {
        name: 'MINING',
        path: '/MINING',
        // component: MINING
    },
    {
        name: 'WOWLEADERBOARD',
        path: '/WOWLEADERBOARD',
        component: LEADERBOARD
    },
    {
        name: 'COINLEADERBOARD',
        path: '/COINLEADERBOARD',
        component: LEADERBOARD
    },

]


const router = createRouter({
  // mode: 'history',
  history:createWebHashHistory(),
  routes
})

export default router

