<template>
        <NavBox />
        <div class="lead_main">
            <img src="../../assets/8-2.jpg"/>

            <div class="lead_content">
                <div class="lead_title">
                    <div v-if="rankType == 1">
                        $WOW Board
                    </div>
                    <div v-if="rankType == 0">
                        Gold Coin Board
                    </div>
                </div>
                <div class="table_main">
                    <table  border="0" class="table_content" style="border-spacing:0;">
                        <tr class="th_main">
                            <th style="border-top-left-radius: 1vw;border-bottom-left-radius: 1vw;">Rank</th>
                            <th>User Name</th>
                            <th>walletaddress</th>
                            <th v-if="rankType == 1">wow</th>
                            <th v-if="rankType == 0">coin</th>
                            <th style="border-top-right-radius: 1vw;border-bottom-right-radius: 1vw;">Level</th>
                        </tr>
                        <tr v-for="(item,index) in rankData" :key="index" class="tr_main">
                            <td class="tr_main.td">{{index + 1}}</td>
                            <td class="tr_main.td">{{item.nickname}}</td>
                            <td class="tr_main.td">{{item.walletaddress}}</td>
                            <td class="tr_main.td" v-if="rankType == 1">{{item.score / 1000000 }} </td>
                            <td class="tr_main.td" v-if="rankType == 0">{{item.score }} </td>
                            <td class="tr_main.td" style="border-top-right-radius: 1vw;border-bottom-right-radius: 1vw;">{{item.level}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
   
</template>
<script setup >
    import {goScrollTop, getRankList} from "../../utils/common.js"
    import NavBox from '../../components/NavBox/NavBox.vue'
    import { onBeforeMount, onMounted, onUpdated, ref} from "vue";
    import { useRoute } from 'vue-router'

    const route = useRoute();
    let rankType = ref(null);

    onBeforeMount(() => {
        rankType.value = route.query.ranktype;
        console.log( rankType.value);
    });
    goScrollTop();
    let rankData = ref();
    async function getRank(){
        const jsonDatas = await  getRankList(rankType.value);
        // console.log(jsonDatas);
        try {
            if(jsonDatas.ret == 0){
                rankData.value = jsonDatas.data.ranks
            }
        } catch (error) {
            console.error('Failed to parse JSON:', error);
        }
        // console.log(rankDataCoin.value);   
       
    }

    onMounted(() => {
        getRank();
    });
    onUpdated(() => {
        window.scrollTo(0, document.body.scrollHeight); 
    });
  

    
</script>
<style scoped lang="less">
@import url("../../css/common.less");


 .lead_main{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    


    img {
        width: 100%;
        float: left;
        position:fixed;
    }

    .lead_content{
        position: relative;
        width: @size-1300;
        // height: @size-970;
        background: #fff;
        top: 0;
        left: 33%;
        right: 20%;
        transform: translateX(-25%);
        border-radius: 2vw;
        margin-top: 37vw;
        overflow: visible;

    }
    .lead_title{
        font-size: @size-48;
        width: 30.3vw;
        height: 6vw;
        background: url("../../assets/8-1.png") no-repeat 0 0;
        background-size: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #fff;
        position: absolute;
        left: 50%;
        transform: translate(-50%,-50%);
        top: 0;
        z-index: 1;
    }
    .table_main{
        width: 100%;
        padding-top: 5.4vw;
        margin: 0 auto;
        position: relative; 
       
    }
    .table_content{
        width: 62vw;
        padding-top: 5.4vw;
        padding-bottom: 5.4vw;
        margin: 0 auto;
        position: relative;   
    }
    .th_main{
        background: #f4f6f9;
        width: 100%;
        min-height: 500px;
        th{
            font-size: @size-18;
            padding: @size-20 0;
        }
    }
    .tr_main{
        td{
            border-bottom: 0.15vw solid #f3f3f3;
            padding: @size-20 0;
            text-align: center;
            font-size: @size-16;
            color:#666666
        }
    
    }  
   
   
  .page_main{
    display: flex;
    font-size: @size-14;
     align-items: center;
    justify-content: center;
    padding-top: @size-30;
    .btn_css{
        width: 3.6vw;
        height: @size-30;
        border: 0.1vw solid #ebebeb;
        border-radius: 0.2vw;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
         margin: 0 @size-10;
    }
    .prev,.next{
          display: flex;
        align-items: center;
        justify-content: center;
         height: @size-30;
          width: @size-30;
          cursor: pointer;
          margin: 0 @size-10;
    }
  } 
 }
</style>
