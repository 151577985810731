<template>
    <div class="nav_main" :class="{'op':op}">
        <div class="nav_content">
            <img class="logo_i" @click="goLine('Home')" src="../../assets/logo.png">
            <div class="nav_menu">
                <!-- <div class="nav_menu_btn" :class="{'isactive':(ntype === 'NFT')}" @click="goLine('NFT')">NFT</div>
                <div class="nav_menu_btn"  :class="{'isactive':(ntype === 'LEADERBOARD')}" @click="goLine('LEADERBOARD')">LEADERBOARD</div>
                <div class="nav_menu_btn"  :class="{'isactive':(ntype === 'WHITEPAPER')}" @click="goLine('WHITEPAPER')">WHITEPAPER</div> -->
                <!-- <div class="nav_menu_btn"  :class="{'isactive':(ntype === 'MINING')}" >MINING</div> -->
                <div class="nav_menu_btn"  @click="goLine('GAME')">GAME</div>
                <div class="nav_menu_btn" style="padding-left:2vw;padding-right:2vw;" :class="{'isactive':(ntype === 'NFT')}" >NFT
                    <div class="connect_menu">
                        <!-- <div class="connect_menu_btn" style="background:#4295f8" @click="goLine('NFT')">NFT</div>
                        <div class="connect_menu_btn"  :class="{'isactive':(ntype === 'AssetsPage')}" @click="goLine('AssetsPage')">AssetsPage</div> -->
                        <div class="connect_menu_btn" style="background:#4295f8">NFT</div>
                        <div class="connect_menu_btn"  :class="{'isactive':(ntype === 'AssetsPage')}" >UPCOMING</div>
                    </div>
                </div>

                <div class="nav_menu_btn"  >
                    LEADERBOARD
                    <div class="connect_menu">
                        <div class="connect_menu_btn">LEADERBOARD</div>
                        <div class="connect_menu_btn"  @click="goLine('WOWLEADERBOARD')">WOW BOARD</div>
                        <div class="connect_menu_btn"  @click="goLine('COINLEADERBOARD')" >Coin BOARD</div>
                    </div>  
                </div>
                <div class="nav_menu_btn"  :class="{'isactive':(ntype === 'WHITEPAPER')}" @click="goLine('WHITEPAPER')">WHITEPAPER</div>
                <div class="nav_menu_btn"  :class="{'isactive':(ntype === 'CONNECT')}">
                    CONNECT
                    <div class="connect_menu">
                        <div class="connect_menu_btn" style="background:#4295f8">CONNECT</div>
                        <!-- <div class="connect_menu_btn"  :class="{'isactive':(ntype === 'AssetsPage')}" @click="goLine('AssetsPage')">Assets</div>
                        <div class="connect_menu_btn">Log out</div> -->
                         <div class="connect_menu_btn"  :class="{'isactive':(ntype === 'AssetsPage')}" >UPCOMING</div>
                    </div>
                </div>
                <div class="nav_btn_menu">
                    <img @click="diaShowEvent(1)" src="../../assets/icon-1.png">
                     <img @click="diaShowEvent(2)" src="../../assets/icon-2.png">
                      <img @click="diaShowEvent(3)" src="../../assets/icon-3.png">
                      <!-- <img src="../../assets/9-4.png" @click="diaShow = true"> -->
                </div>
                <!-- <div>
                    <w3m-button size="md" loadingLabel="connecting..."/>
                </div> -->
            </div>
            <div>
                <LoginDialog v-if="diaShow" @closeDia="closeDia"  />
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from "vue"
import LoginDialog from '../LoginDialog/LoginDialog.vue'
import {useRouter,useRoute} from 'vue-router'
    export default({
        props:['op',],
        setup(){
            const route = useRoute()
            const router = useRouter()
            const wowRankType = 1;
            const coinRankType = 0;
            const goLine = function(url){
                
                if(url == "WHITEPAPER"){
                    window.location.href="https://wowfish.gitbook.io/wowfish-whitepaper/"
                }else if(url == "WOWLEADERBOARD"){
                    router.push({name:'WOWLEADERBOARD',query:{ranktype:1}})
                }else if(url == "COINLEADERBOARD"){
                    router.push({name:'COINLEADERBOARD',query:{ranktype:0}})
                }else if(url == "GAME"){
                    window.location.href="https://web.telegram.org/a/#6748580264"
                }
                else{
                    router.push(url)
                }
                
            }
            let ntype = route.name
            console.log(ntype)
            const type = ref(0);
            const diaShow = ref(false)
            const diaShowEvent = function(type){
                // diaShow.value = true
                let url = "https://t.me/wowfishing"
                if(type == 1){
                    url = "https://discord.com/invite/n2F4qA3Z2g"
                }else if(type == 2){
                    // url="https://twitter.com/Wowfish441950"
                    url="https://twitter.com/WowFish_io"
                }
                window.open(url)
            }
            // const loginShowEvent = function(){
            //     diaShow.value = true
            // }
            const closeDia = function(){
                diaShow.value = false
            }
            return {
                type,
                diaShowEvent,
                closeDia,
                diaShow,
                goLine,
                ntype,
                wowRankType,
                coinRankType
            }
        },
        mounted:function(){
            
        }, 
        components:{
            LoginDialog
        }


    })
</script>
<style scoped lang="less">
@import url("../../css/common.less");
    .nav_main{
        padding: 10px 0;
        // background: rgba(21,124,246,0.6);
        background: rgba(21,124,246,1);
        width: 100%;
        
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        height: 4vw;
        &.op{
            background: rgba(21,124,246,1);
        }
        .nav_content{
            width: 67vw;
            height: 3.6vw;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
             .logo_i{
                    cursor: pointer;
                    width: 16.9vw;
                }
        }
        .nav_menu{
            .nav_menu_btn{
                display: inline-block;
                padding: @size-12  @size-22;
                margin: 0 5px;
                border-radius: 4px;
                font-size: @size-18;
                cursor: pointer;
                color: #fff;
                position: relative;
                &:hover{
                    background: #269bf6;
                    .connect_menu{
                        display: block;
                    }
                }
                &.isactive{
                     background: #269bf6;
                     color: #ffe561;
                }
                .nav_menu_btn{
                    font-size: 0.9vw;
                }
            }
            .nav_btn_menu{
                display: inline-block;
                img{
                    width: @size-30;
                    margin-left: @size-30;
                    cursor: pointer;
                    vertical-align: middle;
                }
            }
        }
        .connect_menu{
            position: absolute;
            display: none;
            border-radius: 4px;
            left: 0;
            top: 0;
            width: 100%;
            background: #2d89f7;
            .connect_menu_btn{
                 border-radius: 4px;
                padding: @size-12 0;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                 &:hover{
                    background: #4295f8;
                }
                &.isactive{
                    color: #ffe561;
                }
            }
        }
    }
    @media screen and (max-width: 1400px) {
         .nav_main{
            .nav_content{
                  .nav_menu_btn{
                     padding: 5px 8px;
                    .nav_menu_btn{
                        font-size: 14px;
                    }
                 }
            }
        }
    }
    @media screen and (min-width: 1920px) {
        .nav_main{
            .nav_content{
                width: 1400px;
                .logo_i{
                    width: 326px;
                }
                 .nav_menu_btn{
                     padding: 12px 22px;
                    .nav_menu_btn{
                        font-size: 18px;
                    }
                 }
               .nav_btn_menu{
                img{
                    width: 30px;
                    margin-left: 30px;
                }
            }
            }
            .connect_menu{

            .connect_menu_btn{
                padding: 12px 0;
            }
        }
        }
    }
</style>