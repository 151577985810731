<template>
    <NavBox></NavBox>
    <div class="home_main">
        <div class="home_page1">
            <img src="../../assets/banner20240430/5.png" class="banner_img">
            <div class="play_btn" @click="qrShowEvent"></div>
           
        </div>
        <img src="../../assets/ling.png" class="ling_bg">
         <div class="home_page2">
            <div class="dia_log_swipr">   </div>    
            <div class="home_page2_info">
                <div class="home_page2_card1">
                    <div class="home_page2_card1_l">
                        <p class="title">Telegram bot beta version launching in April!</p>
                        <p class="contents">Limited-time free airdrop rewards available. Assets reserved during the testing period will carry over upon official release! Seize the opportunity now to become the ocean overlord and win OG rewards!</p>
                    </div>
                    <div class="home_page2_card1_r">
                        <!-- <img src="../../assets/0-2.png" /> -->
                        <!-- <video type="video/mp4" src="../../assets/movie.mp4" id="video" poster="../../assets/0-2.png"></video> -->
                        <img class="border_bg" src="../../assets/1-2.png" />
                        <!-- <img class="video_icon" v-if="!isPlay" @click="videoPlay" src="../../assets/icon-4.png" /> -->
                       <!-- <iframe width="97.6%" height="97%" style="border-radius: 2.8vw" src="https://www.youtube.com/embed/MxfvEmMQSmc?si=qeP_u8vKowRY6-zc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
                       <iframe width="97.6%" height="97%" style="border-radius: 2.8vw" src="https://www.youtube.com/embed/24BdGkxDY18?si=cko1xJ6b0HqoqEtu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen = fallse></iframe>
                      <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/24BdGkxDY18?si=cko1xJ6b0HqoqEtu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> -->

                    </div>
                    <img class="fish_1" src="../../assets/2-1.png" />
               
                </div>
                <img class="lang_img1" src="../../assets/1-3.png" />
              
                <div class="home_page2_card2">
                    <div class="swiper_cards">
                        <swiper
                            :modules="modules"
                            :autoplay="{
                                delay: 3500,
                                disableOnInteraction: false,
                            }"
                            :loop="true"
                            :slides-per-view="1"
                            :space-between="0"
                            :auto-height="true"
                            
                            :pagination="{ 
                                clickable: true 
                            }"
                            style="z-index: 5;  border-radius: 4vw;"                           
                        >
                      
                            <swiper-slide >
                                <img class="swiper_img" src="../../assets/banner20240430/1.png">                        
                            </swiper-slide>
                            <swiper-slide>
                                <img class="swiper_img" src="../../assets/banner20240430/2.png">
                            </swiper-slide>
                            <swiper-slide>
                                <img class="swiper_img" src="../../assets/banner20240430/3.png">
                            </swiper-slide>
                            <!-- <div class="swiper-pagination"></div> -->
                        </swiper>
                    </div>
                </div>
            </div>
          
            <p class="page_titles">
                <img class="fish_img2" src="../../assets/2-2.png">
            </p>
            <div class="home_page2_info" style="height:35.4vw;padding-top:5vw">

                    <swiper
                    :modules="modulesT"
                    :slides-per-view="3"
                    :space-between="0"
                    :autoplay="{
                        delay: 3000,
                        disableOnInteraction: false,
                    }"
                    :navigation="true"
                    :pagination="{
                        clickable:true
                    }"
                    :loop="true"
                    :autoHeight="true"
                    @setTranslate="setTranslate" 
                    @transitionEnd="transitionEnd"
                 
                    >
                    <swiper-slide>
                            <div class="box">
                                <div class="img_box">
                                    <img src="../../assets/NFT/0-4-1.png" />
                                </div>
                                <p class="swiper_titles">Coming soon!</p>
                                <p class="swiper_content">Stay tuned for more NFT gameplay.</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide><div class="box">
                            <div class="img_box">
                                    <img src="../../assets/NFT/CannonNFT.png" />
                            </div>
                            <p class="swiper_titles">Cannon NFT</p>
                            <p class="swiper_content">Purchase and upgrade your cannon NFT to earn $WOW. </p>

                        </div></swiper-slide>
                        <swiper-slide>
                            <div class="box">
                                <div class="img_box">
                                        <img src="../../assets/NFT/FisheryNFT.png" />
                                </div>        
                                <p class="swiper_titles">Fishery NFT</p>
                                <p class="swiper_content">Mint Fishery NFTs, stake them, and share $WOW rewards as a fisherman's gain.</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="box">
                                <div class="img_box">
                                    <img src="../../assets/NFT/0-4-1.png" />
                                </div>
                                <p class="swiper_titles">Coming soon!</p>
                                <p class="swiper_content">Stay tuned for more NFT gameplay.</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide><div class="box">
                            <div class="img_box">
                                    <img src="../../assets/NFT/CannonNFT.png" />
                            </div>
                            <p class="swiper_titles">Cannon NFT</p>
                            <p class="swiper_content">Purchase and upgrade your cannon NFT to earn $WOW. </p>

                        </div></swiper-slide>
                        <swiper-slide>
                            <div class="box">
                                <div class="img_box">
                                        <img src="../../assets/NFT/FisheryNFT.png" />
                                </div>        
                                <p class="swiper_titles">Fishery NFT</p>
                                <p class="swiper_content">Mint Fishery NFTs, stake them, and share $WOW rewards as a fisherman's gain.</p>
                            </div>
                        </swiper-slide>
                    </swiper>
          
            </div>
            <div class="dia_log_swipr"></div>
            <p class="page_titles page_titles2">
                <img class="fish_img3" src="../../assets/2-3.png">
            </p>
            <p class="page_texts">Explore The Fishing World</p>
            <div class="line_box">
                <img class="r_icon" src="../../assets/3-2.png" />
                <img class="j_icon" src="../../assets/3-3.png">
                <img class="r_icon p1" src="../../assets/3-2.png" />
                 <img class="j_icon p1" src="../../assets/3-3.png">
                <img class="r_icon p2" src="../../assets/3-2.png" />
                 <img class="j_icon p2" src="../../assets/3-3.png">
                <img class="r_icon p3" src="../../assets/3-2.png" />
                <div class="q_main">
                    <p>2024<span>Q1</span></p>
                </div>
                 <div class="q_main p1">
                    <p>2024<span>Q2</span></p>
                </div>
                 <div class="q_main p2">
                    <p>2024<span>Q3</span></p>
                </div>
                 <div class="q_main p3">
                    <p>2024<span>Q4</span></p>
                </div>
                <p class="q_main_text">Beta Version</p>
                <p class="q_main_text p1">Alpha Version</p>
                <p class="q_main_text p2">Mena Version</p>
                <p class="q_main_text p3">+X</p>
            </div>
           
            <p class="page_titles page_titles3">
                <img class="fish_img2" src="../../assets/2-4.png">
            </p>
            <div class="follow_main">
                <div class="follow_content">
                    <div class="follow_box">
                         <img src="../../assets/partners/1.png">
                    </div>
                    <div class="follow_box">
                        <img src="../../assets/partners/2.png">
                    </div>
                    <div class="follow_box">
                        <img src="../../assets/partners/3.png">
                    </div>
                </div>
                <div class="follow_content">
                    <div class="follow_box">
                        <img src="../../assets/partners/4.png">
                    </div>
                    <div class="follow_box">
                        <img src="../../assets/partners/5.png">
                    </div>
                    <div class="follow_box">
                        <img src="../../assets/partners/6.png">
                    </div>
                    <div class="follow_box">
                        <img src="../../assets/partners/7.png">
                    </div>
                </div>
            </div>
         </div>
    </div>
    <div class="qr_dia" v-if="qrDisplay">
        <div class="qr_dia_main">
            <img class="qr_close" @click="qrClose" src="../../assets/3-5.png">
            <!--二维码-->
            <img class="qr_main" src="" >
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import {goScrollTop} from "../../utils/common.js"
import NavBox from '../../components/NavBox/NavBox.vue'
  import { Swiper, SwiperSlide } from 'swiper/vue';
   import {  Pagination,Autoplay,Navigation} from 'swiper/modules';
import { onMounted, ref } from 'vue';
  import 'swiper/css/pagination';
   import 'swiper/css/navigation';
export default defineComponent({
    setup() {
      
        goScrollTop()
        const activeIndex= ref(1)
        const qrDisplay = ref(false)
        const isPlay = ref(false)
        const qrClose = function(){
            qrDisplay.value = false
        }
        const qrShowEvent = function(){
            // qrDisplay.value = true
            window.location.href = "https://game.wowfish.io/"
        }

        const onSlideChange = function(e){
            console.log(e.activeIndex)
            activeIndex.value = e.activeIndex
        }

        const videoPlay = function(){
            let video = document.getElementById("video")
            video.play()
            
        }
        const changeVEvent = function(){
                   
            let video = document.getElementById("video")
            if(isPlay.value){
                video.pause()
                isPlay.value = false
            }else{
                isPlay.value = true
                video.play()
            }

      
        }

        onMounted(()=>{
            // let video = document.getElementById("video")

            // video.addEventListener('play', function() {
            //     isPlay.value = true
            // });
            // video.addEventListener('ended', function() {
            //     isPlay.value = false
            // });
        })

      

        return {
             modules: [Pagination,Autoplay],
             modulesT:[Pagination,Autoplay,Navigation],
             qrClose,
             qrShowEvent,
             qrDisplay,
             onSlideChange,
             activeIndex,
             videoPlay,
             isPlay,
             changeVEvent
        }
    },
    components:{
        NavBox,
        Swiper,
        SwiperSlide,
    }
})
</script>
<style lang="less" scoped>
@import url("../../css/common.less");
.follow_main{
    padding: @size-126 0 0 0;
    text-align: center;
    .follow_box{
        display: inline-block;
        width: @size-278;
        // height: @size-94;
        border-radius: 0.8vw;
        border: 0.2vw solid #26feff;
        background: #333;
        margin: 0 @size-54 @size-80 0;
        overflow: hidden;
        img{
            width: 100%;
            float: left;
            cursor: pointer;
        }
        &:last-child{
            margin-right: 0;
        }
    }
}

.fish_img2{
    position: absolute;
    width: @size-114;
    height: @size-65;
    bottom: -1vw;
    right: -6vw;
}
.fish_img3{
       position: absolute;
    width: @size-84;
    height: @size-48;
    bottom: -1vw;
    left: -5vw;
}
.qr_dia{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.6);
    z-index: 20;
    .qr_dia_main{
        width: @size-404;
        height: @size-412;
        background: url('../../assets/3-4.png') no-repeat 0 0;
        background-size:100% 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .qr_close{
        width: @size-36;
         position: absolute;
        top: 4%;
        right: 4%;
        cursor: pointer;
    }
    .qr_main{
        width: @size-303;
         position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}
    .home_page1{
        position: relative;
        z-index: 8;
        // background: #087de8;
        height: @size-517;
        .play_btn{
            width: 16.8vw;
            height: 5.3vw;
            position: relative;
            background: url('../../assets/pn_btn.jpg') no-repeat 0 0;
            background-size: 100%;
            // position: absolute;
            left: 50%;
            top: -25%;
            transform: translate(-50%,-50%);
            cursor: pointer;
            border-radius: 10vw;
            z-index: 7;
        }
  
        .banner_img{
            // top: -10vw;
            height: @size-1000;
            width: 100%;
            z-index: 5;

        }
    }
    .ling_bg{
        width: 100%;
        position: relative;
        left: 0;
        bottom: -50%;
        z-index: 5;
        top: 12vw;
    
    }

    .home_page2{
        width: 100%;
        min-height: 209vw;
        background: url('../../assets/0-0jpg.png') no-repeat 0 0;
        background-size: 100% 100%;
        position: relative;
        top:  0vw;
        z-index: 4;
        .home_page2_info{
            width: @size-1300;
            margin: 0 auto;
             padding-top: @size-110;
             position: relative;
             z-index: 5;
             .dia_log_swipr{
                width: 90%;
                height: 100%;
                // position: absolute;
                position: relative;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                z-index: 5;
             }
        }
        .home_page2_card1{
            position: relative;
            height: @size-517;
            background: url('../../assets/1-4.png') no-repeat 0 0;
            background-size: auto 100%;
            .home_page2_card1_l{
                width: @size-360;
                padding:  @size-62 0 0 @size-62;
                color: #fff;
                .title{
                    font-weight: bold;
                    font-size: @size-36;
                }
                .contents{
                    font-size: @size-18;
                    padding:  @size-22 0 0 0;
                }
            }
            .home_page2_card1_r{
                position: absolute;
                top: 0;
                right: 0;
                width: @size-874;
                height: 25.2vw;
                background-size: 100%;
                overflow: hidden;
                border-radius: 3vw;
                z-index: 5;
                iframe{
                    border-radius: 1.8vw;
                    position: relative;
                    z-index: 1;
                    top: 1.8%;
                    left: 1%;
                }
                video{
                    width: 100%;
                    border-radius: 3vw;
                }
                img{
                    width: 100%;
                }
                .border_bg{
                    width: @size-874;
                    height: @size-488;
                    position: absolute;
                    top: 0;
                    right: 0;
                    cursor: pointer;
                }
                .video_icon{
                     width: @size-94;
                    height: @size-94;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    cursor: pointer;
                }
             
            }
        }
        .home_page2_card2{
            // width: @size-1300;
            margin: @size-110 auto 0;
            position: relative;
            // height: @size-620;
            .swiper_cards{
                background-image: url('../../assets/banner20240430/banner-bg.png');
                background-size: cover;
                background-position: relative;
                background-repeat: no-repeat;
                padding: 2px; /* Adjust as necessary to provide proper padding around Swiper */
                width: 100%;
                // width: @size-1300;
                height: 100%;
                // height: @size-480;
                border-radius: 4vw;
                z-index: 4;
                .swiper_img{
                    // position: relative;
                    // top: 5px;
                    // left: 5px;
                    // right: 5px;
                    // bottom: 5px;
                    width: 100%;
                    height: 100%;
                    float: left;
                }
            }
            
        }
    }
    .line_box{
        height: 0.3vw;
        background: #35bceb;
        width: @size-1250;
        margin: @size-368 auto 0;
        position: relative;
        .q_main{
            width: @size-200;
            height: @size-200;
            background: url("../../assets/3-1.png") no-repeat 0 0;
            background-size:100% ;
            position: absolute;
            text-align: center;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            top: -13vw;
            left: -4.6%;
            p{
                font-size: @size-30;
                span{
                    font-size:@size-48 ;
                    display: block;
                }
            }
            &.p1{
                left: 26.4%;
            }
            &.p2{
               left: 59%; 
            }
            &.p3{
                left: 93%;
            }
        }
        .q_main_text{
            position: absolute;
            bottom: -3vw;
            font-size: @size-30;
            color: #fff;
            font-weight: bold;
             transform: translateX(-50%);
            &.p1{
               left: 34%;
            }
            &.p2{
                left: 67%;
            }
            &.p3{
                left: 101%;
            }
        }
        .r_icon{
            width: @size-36;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            &.p1{
                left: 33%;
            }
            &.p2{
                left: 66%;
            }
            &.p3{
                left: 100%;
            }
        }
        .j_icon{
            width: @size-72;
            height: @size-50;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 15%;
             &.p1{
                left: 48%;
            }
            &.p2{
                left: 83%;
            }
        }
    }
    .page_titles{
        width: @size-620;
        height: @size-144;
        background: url('../../assets/title/title1.png') no-repeat 0 0;
        background-size: 100%;
        margin: @size-168 auto 0;
        position: relative;
    }
    .page_titles2{
         background: url('../../assets/title/title2.png') no-repeat 0 0;
        background-size: 100%;
    }
    .page_titles3{
        margin-top: @size-428;
        background: url('../../assets/title/title4.png') no-repeat 0 0;
        background-size: 100%;
    }
    .page_texts{
        text-align: center;
        color: #fff;
        font-size: @size-36;
        font-weight: bold;
        padding-top: @size-32;
    }
   .lang_img1{
        width: @size-210;
        height: @size-168;
        position: absolute;
        bottom: 2vw;
        right: -2vw;
        z-index: 0;
    }
    .fish_1{
         width: @size-102;
        height: @size-72;
        position: absolute;
        bottom: 3.2vw;
        left: 14vw;
    }
 
    @media screen and (max-width: 1300px) {

    }
    @media screen and (min-width: 1920px) {
        .home_page2{
            .home_page2_info{
                width: 1300px;
                top: 50px;
            }
        }
    }

.box{
    width:100%;
    background:#fff;
    border-radius: 3vw;
    padding: 1.1vw;
}
.swiper-slide .box{
  transition:transform 0.2s;
  position: relative;
  right: 2.2vw;
    transform:scale(0.7);
     height: 35.4vw;
     .img_box{
        width: 100%;
        height: 24.1vw;
        overflow: hidden;
        position: relative;
        img{
          position: absolute;
          top: 0;
          right: 0;
          height: 24.1vw;
        }
     }
      .swiper_titles{
        display: none;
     }
     .swiper_content{
        padding-top: @size-102;
        color:#666;
        font-size: @size-18;
        overflow: hidden;
     }
     
}
// .swiper-slide.swiper-slide-next .box{
//   trnsition:transform 0.2s;
// transform:scale(0.7);
// }
 .swiper-slide.swiper-slide-next .box{
    transition:transform 0.2s;
    transform:scale(1) translateX(-24.9%);
    right: 0;
    width:43vw;
    height: 33vw;
    .img_box{
        img{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
     }
    .swiper_titles{
        color:#157cf6;
        font-size: @size-36;
        text-align: center;
        padding-top: @size-30;
        font-weight: bold;
        display: block;
     }
     .swiper_content{
        padding-top: @size-24;
        color:#666;
        font-size: @size-18;
     }
}
 .swiper-slide.swiper-slide-active .box{
 
    transition:transform 0.2s;
    transform:scale(0.7);
    right: 0;
    .img_box{
       img{
         position: absolute;
          top: 0;
          left: 0;
       }
     }
    
}
 .swiper-slide.swiper-slide-next{
    z-index:1
}

</style>
<style  lang="less">
@import url("../../css/common.less");
.swiper-button-next{
    width: 2.39vw !important;
    height: 2.55vw !important;
    background: url("../../assets/icon-5-2.png") no-repeat 0 0 !important;
    background-size:100% 100% !important;
    // right:-1.9vw !im;
    &::after{
        display: none;
    }
}
.swiper-button-prev{
    width: 2.39vw !important;
    height: 2.55vw !important;
    background: url("../../assets/icon-5-1.png") no-repeat 0 0 !important;
    background-size:100% 100% !important;
    // right:-1.9vw !im;
    &::after{
        display: none;
    }
}

    .home_main{
        position: relative;
        padding-top: 4.9vw;

        z-index: 1;
        margin-top: 20px;
        background: rgba(21,124,246,1);
        
        .swiper-pagination-bullet{
            width: @size-40 !important;
            height: @size-10 !important;
            background: #d1ecf4  !important;
            border-radius: 10px ;
        }
    }
    .home_page2_info{
     .swiper-pagination-bullet{
            width: @size-12 !important;
            height: @size-12 !important;
            background: #fff  !important;
            margin: 0 @size-18 !important ;
            transform: rotate(45deg);
            opacity: 1 !important;
             border-radius: 0.12vw !important ;
            &.swiper-pagination-bullet-active{
                width: @size-20 !important;
                 height: @size-20 !important;
                 background: linear-gradient(#feee90, #f8bb6f) !important;
                 position: relative;
                 top: 0.2vw;
            }
        }
        .swiper-horizontal > .swiper-pagination-bullets{
            bottom: 0 !important;
        }
        .swiper{
            padding:0 0 1vw 0 !important;
        }
}
     @media screen and (min-width: 1920px) {
        width: 40px;
        height: 10px;
     }
    .swiper-horizontal>.swiper-pagination-bullets{
          z-index: 2;
           bottom: 5vw !important;
    }
</style>